<template>
  <div id="app">
    <div id="blur-wrapper">
      <router-view
        v-if="subscriptionType || overrideTest"
        :storeUTMValues="storeUTMValues"
      ></router-view>
      <div class="flex items-center justify-center w-screen h-screen" v-else>
        <Loader />
      </div>
    </div>
    <portal-target name="overlay"> </portal-target>
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";
import API from "./api";
import _ from "lodash";

export default {
  name: "App",
  components: {
    Loader,
  },
  data() {
    return {
      overrideTest: false,
      stripeProductCode: false,
      storeUTMValues: {},
      isAmericas: false,
      isBetPromoAllowed: false,
    };
  },
  async mounted() {
    console.log("****** APP MOUNTED");
    this.isAmericas = await this.checkUserLocation("america");
    this.isBetPromoAllowed = await this.checkUserLocation("betpromo");
    // checkUserLocation((isInAmericaOrCanada) => {
    //   if (isInAmericaOrCanada) {
    //     if (this.$route.params.type == "monthly") {
    //       this.stripeProductCode = stripeCodes["america"]["monthly"];
    //     }
    //     if (this.$route.params.type == "annual") {
    //       this.stripeProductCode = stripeCodes["america"]["annual"];
    //     }
    //     if (this.$route.params.type == "free") {
    //       this.stripeProductCode = false;
    //     }
    //     this.$store.commit("updateStripeCode", this.stripeProductCode);
    //   } else {
    //     if (this.$route.params.type == "monthly") {
    //       this.stripeProductCode = stripeCodes["europe"]["monthly"];
    //     }
    //     if (this.$route.params.type == "annual") {
    //       this.stripeProductCode = stripeCodes["europe"]["annual"];
    //     }
    //     if (this.$route.params.type == "free") {
    //       this.stripeProductCode = false;
    //     }
    //     this.$store.commit("updateStripeCode", this.stripeProductCode);
    //   }
    // });

    setTimeout(() => {
      this.storeUTM();
    }, 200);

    if (this.$route.path == "/index.html") {
      this.$router.push("/");
    }
    if (localStorage.getItem("NGAccessTokens")) {
      this.$store.commit(
        "storeAccessTokens",
        JSON.parse(localStorage.getItem("NGAccessTokens"))
      );
      let auth = {
        idToken: this.$store.getters.accessTokens.id_token,
      };
      API.get("/user", auth)
        .then((response) => {
          this.$store.commit("storeUserID", response.data.user_id);
          this.$store.commit(
            "storeSubscriptionType",
            response.data.subscription
          );
          this.$store.commit(
            "storeCustomerID",
            response.data.stripe_customer_id
          );
          console.log(this.subscriptionType, this.$route.path);
          if (
            this.subscriptionType == false &&
            this.$route.path == "/predictions"
          ) {
            // A user is on predictions page and they shouldn't be.
            this.$router.push("/");
          }
        })
        .catch(() => {});
    } else {
      setTimeout(() => {
        if (this.$route.path == "/predictions") {
          // A user is on predictions page and they shouldn't be.
          this.$router.push("/");
        }
        this.overrideTest = true;
      }, 100);
    }
  },
  methods: {
    async checkUserLocation(type) {
      if (type == "america") {
        return false;
      }
      return false;
      // return await fetch("https://ipapi.co/json")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     const country = data.country.toLowerCase();
      //     const isInAmerica = country === "us";
      //     const isInCanada = country === "ca";
      //     if (type == "america") {
      //       return isInAmerica || isInCanada;
      //     }
      //     const isInKenya = country === "ke";
      //     const isInNigeria = country === "ng";
      //     const isInGhana = country === "gh";
      //     if (type == "betpromo") {
      //       return isInKenya || isInNigeria || isInGhana;
      //     }
      //   })
      //   .catch(() => {
      //     return false;
      //   });
    },
    storeUTM() {
      this.storeUTMValues = {
        utm_campaign: this.$route.query.utm_campaign
          ? this.$route.query.utm_campaign
          : "",
        utm_content: this.$route.query.utm_content
          ? this.$route.query.utm_content
          : "",
        utm_medium: this.$route.query.utm_medium
          ? this.$route.query.utm_medium
          : "",
        utm_source: this.$route.query.utm_source
          ? this.$route.query.utm_source
          : "",
        referrer: window.frames.top.document.referrer
          ? window.frames.top.document.referrer
          : "",
        branch: "no-free-user-signup",
      };

      this.storeUTMValues = _.omitBy(
        this.storeUTMValues,
        (item) => item === ""
      );
    },
  },
  watch: {
    "$route.path"() {
      document.body.classList.remove("h-screen");
      document.body.classList.remove("overflow-hidden");
      if (this.$route.path == "/predictions") {
        document.title = "Numbers Game | predictions";
      } else {
        document.title = "Numbers Game | Football Stats Made Easy";
      }
    },
  },
  computed: {
    subscriptionType() {
      return this.$store.getters.subscriptionType;
    },
    type() {
      return this.$route.params.type;
    },
  },
};
</script>

<style></style>
